<script>
import { createPageable, createLoadable, isResponseSuccesful, setLoadableResponse, setPageableResponse, toggleLoadable, togglePageable } from '@/utils/loadable';
import ProgresiveLoadAutocomplete from '@/components/utils/ProgresiveLoadAutocomplete.vue';
import DataTableComponent from '@/components/DataTableComponent.vue';
import NavButton from '@/components/utils/NavButton.vue';
import moment from "moment";
import PdfModal from '@/components/PdfModal.vue';
import { ConfirmationModalComponent } from '@/components/utils';

export default {
    components: { NavButton, ProgresiveLoadAutocomplete, DataTableComponent, PdfModal, ConfirmationModalComponent },
    data: () => ({
        registros: createPageable([], 10),
        pdfLoadable: createLoadable(null),
        headers: [
            { text: 'Institución', value: 'institucion', align: 'center', sortable: false },
            { text: 'Nombre de la compra', value: 'nombre_compra', align: 'center', sortable: false },
            { text: 'Fecha de contratacón', value: 'fecha_contratacion', align: 'center', sortable: false },
            { text: 'Monto contratado', value: 'monto_contratado', align: 'center', sortable: false },
            { text: 'País', value: 'pais', align: 'center', sortable: false },
            { text: 'Acciones', value: 'acciones', align: 'center', sortable: false },
        ],
        RangoFechas: false,
        fechas: { start: null, end: null },
        paginaActual: 1,
        registrosPorPagina: 10,
        showModal: false,
        isDeleteConfirmOpen: false,
        isPdfModalOpen: false,
        detalleCompra: [],
        panel: 0,
        filtros: {
            institucion: null,
            pais: null,
            fecha_desde: null,
            fecha_hasta: null,
            usuario: null,
            estado: null,
        },
        busquedas: {
            institucion: null,
            pais: null,
            usuario: null
        },
        // Para el autocomplete de instituciones
        instituciones: createPageable([], 30),
        paginacionInstituciones: {
            pagina: 1,
            registrosPorPagina: 30,
        },
        // Para el autocomplete de paises
        paises: createPageable([], 30),
        paginacionPaises: {
            pagina: 1,
            registrosPorPagina: 30,
        },
        // Para el autocomplete de usuarios
        usuarios: createPageable([], 30),
        paginacionUsuarios: {
            pagina: 1,
            registrosPorPagina: 30,
        },
        search: null,
        file: null,
        documentoUsuario: null,
        itemToDelete: null,
    }),
    computed: {
        total() {
            return this.registros.pagination.total_rows;
        },
        dateRangeText() {
            return this.fechas && this.fechas.start && this.fechas.end
                ? moment(this.fechas.start).format("D/M/Y") +
                " - " +
                moment(this.fechas.end).format("D/M/Y")
                : "Selecciona un rango de fechas";
        },
    },
    methods: {
        moment,
        manejarPaginacionRegistros(paginacion) {
            const { pagina, cantidad_por_pagina } = paginacion;
            this.paginaActual = pagina;
            this.registrosPorPagina = cantidad_por_pagina;
            this.revisionComprasExterior();
        },
        async revisionComprasExterior() {
            togglePageable(this.registros);

            const filtros = {
                per_page: this.registrosPorPagina,
                page: this.paginaActual,
                idInstitucion: this.filtros.institucion,
                idPais: this.filtros.pais,
                fechaInicio: this.filtros.fecha_desde,
                fechaFin: this.filtros.fecha_hasta,
                IdUsuario: this.filtros.usuario,
                estado: this.search,
            }

            const data = await this.services.compraEnExterior.revisionCompras(filtros);
            setPageableResponse(this.registros, data, data.headers);
        },
        async openModal(id) {
            const compra = this.registros.data.find(item => item.id === id);
            if (compra) {
                this.detalleCompra = compra;
                this.showModal = true;
            }
        },
        async confirmFileDelete(id) {
            const compra = this.registros.data.find(item => item.id === id);
            if (compra) {
                this.detalleCompra = compra;
                this.itemToDelete = id;
                this.isDeleteConfirmOpen = true;
            }
        },
        async showAttachment(id) {
            try {
                this.isPdfModalOpen = true;
                toggleLoadable(this.pdfLoadable);
                const { data } = await this.services.compraEnExterior.visualizarDocumento(id);
                setLoadableResponse(this.pdfLoadable, data, { isFile: true });
            } catch (error) {
                console.error('Error al cargar el documento:', error);
                this.isPdfModalOpen = false;
            }
        },
        async showAttachmentIsDelete(ruta) {

            let params = {
                uri: ruta
            }
            try {
                this.isPdfModalOpen = true;
                toggleLoadable(this.pdfLoadable);
                const { data } = await this.services.compraEnExterior.visualizarDocumentoEliminacion(params);
                setLoadableResponse(this.pdfLoadable, data, { isFile: true });
            } catch (error) {
                console.error('Error al cargar el documento:', error);
                this.isPdfModalOpen = false;
            }
        },
        // Métodos para el manejo de instituciones
        async buscarInstitucion(termino) {
            if (this.busquedas.institucion === termino) return;

            this.busquedas.institucion = termino;
            this.instituciones.data = [];
            this.paginacionInstituciones.pagina = 1;

            this.cargarInstituciones();
        },
        async cargarInstituciones() {
            const filtros = {
                pagination: true,
                per_page: this.paginacionInstituciones.registrosPorPagina,
                page: this.paginacionInstituciones.pagina,
                busqueda: this.busquedas.institucion,
                excluir_centros_escolares: true,
            };

            togglePageable(this.instituciones);
            const { data, headers } = await this.services.BajaCuantia.cargarInstituciones(filtros);

            const copiaInstituciones = this.instituciones.data;
            setPageableResponse(this.instituciones, data, headers, { skipOnSuccess: true, persistDataOnError: true });

            if (!isResponseSuccesful(data)) {
                const { pagina } = this.paginacionInstituciones;
                this.paginacionInstituciones.pagina = pagina > 1 ? pagina - 1 : 1;
                return;
            };

            this.instituciones.data = copiaInstituciones.concat(data.data);
        },
        cargarMasInstituciones() {
            const { page, per_page, total_rows } = this.instituciones.pagination;
            if (page * per_page >= total_rows) return;

            this.paginacionInstituciones.pagina = page + 1;
            this.cargarInstituciones();
        },
        // Métodos para el manejo de paises
        async buscarPais(termino) {
            if (this.busquedas.pais === termino) return;

            this.busquedas.pais = termino;
            this.paises.data = [];
            this.paginacionPaises.pagina = 1;

            this.getPaises();
        },
        async getPaises() {

            const filtros = {
                pagination: true,
                per_page: this.paginacionPaises.registrosPorPagina,
                page: this.paginacionPaises.pagina,
                nombre: this.busquedas.pais,
            };

            togglePageable(this.paises);
            const data = await this.services.Paises.getListaPaises(filtros);

            const copiaPaises = this.paises.data;

            setPageableResponse(this.paises, data, data.headers, { skipOnSuccess: true, persistDataOnError: true });

            if (!isResponseSuccesful(data)) {
                const { pagina } = this.paginacionPaises;
                this.paginacionPaises.pagina = pagina > 1 ? pagina - 1 : 1;
                return;
            };

            this.paises.data = copiaPaises.concat(data.data);
        },
        cargarMasPaises() {
            const { page, per_page, total_rows } = this.paises.pagination;
            if (page * per_page >= total_rows) return;

            this.paginacionPaises.pagina = page + 1;
            this.getPaises();
        },
        // Métodos para el manejo de usuarios por id de institución
        async buscarUsuario(termino) {
            if (this.busquedas.usuario === termino) return;

            this.busquedas.usuario = termino;
            this.usuarios.data = [];
            this.paginacionUsuarios.pagina = 1;

            this.getUsuariosInstitucion();
        },
        async getUsuariosInstitucion() {
            if (!this.filtros.institucion)
                return;

            const filtros = {
                pagination: true,
                per_page: this.paginacionUsuarios.registrosPorPagina,
                page: this.paginacionUsuarios.pagina,
                idInstitucion: this.filtros.institucion,
                nombre: this.busquedas.usuario
            };

            togglePageable(this.usuarios);

            const data = await this.services.compraEnExterior.usuariosInstitucion(filtros);

            const copiaUsuariosInstitucion = this.usuarios.data;

            setPageableResponse(this.usuarios, data, data.headers);

            if (!isResponseSuccesful(data)) {
                const { pagina } = this.paginacionUsuarios;
                this.paginacionUsuarios.pagina = pagina > 1 ? pagina - 1 : 1;
                return;
            }

            this.usuarios.data = copiaUsuariosInstitucion.concat(data.data);
        },

        cargarMasUsuarios() {
            const { page, per_page, total_rows } = this.usuarios.pagination;

            if (page * per_page >= total_rows) return;

            if (this.filtros.institucion) {
                this.paginacionUsuarios.pagina = page + 1;
                this.getUsuariosInstitucion();
            }
        },
        // Métodos para los filtros
        buscar() {
            this.paginaActual = 1;
            this.revisionComprasExterior();
        },
        limpiarFiltros() {
            this.paginaActual = 1;
            this.filtros = {
                institucion: null,
                pais: null,
                fecha_desde: null,
                fecha_hasta: null,
                usuario: null,
            };
            this.busquedas = {
                institucion: null,
                pais: null,
                usuario: null
            };
            this.fechas = {
                start: null,
                end: null,
            },

                this.search = null,

                this.instituciones = createPageable([]);
            this.paises = createPageable([]);
            this.usuarios = createPageable([]);
            this.revisionComprasExterior();
        },
        setFiltrosFechas() {
            if (this.fechas && this.fechas.start && this.fechas.end) {
                this.filtros.fecha_desde = moment(this.fechas.start).format("YYYY-MM-DD");
                this.filtros.fecha_hasta = moment(this.fechas.end).format("YYYY-MM-DD");
            } else {
                this.filtros.fecha_desde = null;
                this.filtros.fecha_hasta = null;
            }
        },
        estado(value) {
            this.search = value
        },
        getRowClass(item) {
            return item.deleted_at !== null ? "invalidRow" : "";
        },
        async confirmDelete() {
            if (!this.file || !this.itemToDelete) return;

            try {
                let urlArchivo = await this.obtainUrlDocumento();

                const params = {
                    id: this.itemToDelete,
                    urlDocumentoEliminacion: urlArchivo
                };

                const response = await this.services.compraEnExterior.eliminarCompra(params);

                if (response) {
                    this.temporalAlert({
                        show: true,
                        type: "success",
                        message: response?.data?.message,
                    });

                    // Cerrar modal y limpiar variables
                    this.isDeleteConfirmOpen = false;
                    this.file = null;
                    this.itemToDelete = null;

                    // Actualizar la tabla
                    await this.revisionComprasExterior();
                }
            } catch (error) {
                console.error(error);
                this.temporalAlert({
                    show: true,
                    type: "error",
                    message: "Error al eliminar la compra",
                });
            }
        },

        async obtainUrlDocumento() {
            try {
                let formData = new FormData();
                formData.append("documentoEliminacion", this.file);
                const response = await this.services.compraEnExterior.uploadFile(formData);
                if (response) {
                    return response?.data?.urlDocumentoEliminacion;
                }
                return null;
            } catch (error) {
                console.error(error);
                throw error;
            }
        },
    },
    watch: {
        "filtros.institucion": {
            handler(nuevaInstitucion) {
                if (nuevaInstitucion) {
                    this.paginacionUsuarios.pagina = 1;
                    this.usuarios.data = [];
                    this.getUsuariosInstitucion();
                } else {
                    this.usuarios.data = [];
                }
            },
            immediate: true,
        },
    },

    created() {
        this.revisionComprasExterior();
        this.cargarInstituciones();
        this.getPaises();
    },
}
</script>

<template>
    <v-container>
        <v-card>
            <div class="d-flex align-center px-4 py-4" style="gap: 16px;">
                <NavButton />
                <h2 class="text-h6 text-uppercase font-weight-bold">Revisión de Compras en el Exterior</h2>
            </div>
            <v-divider />

            <div class="px-4 py-4" style="position: relative;">
                <v-expansion-panels class="mt-4" v-model="panel">
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <div class="d-flex align-center" style="gap: 8px;">
                                <v-icon>mdi-filter-settings</v-icon>
                                <span class="text-subtitle-1 font-weight-bold text-uppercase">Filtros</span>
                            </div>
                        </v-expansion-panel-header>
                        <v-divider />
                        <v-expansion-panel-content>
                            <div class="px-4 py-4">
                                <v-row dense align="center">
                                    <v-col cols="12" lg="4">
                                        <ProgresiveLoadAutocomplete v-model="filtros.institucion"
                                            :pageable="instituciones" :loading="instituciones.isLoading"
                                            class="flex-grow-1 flex-shrink-1" style="min-width: 200px;"
                                            label="Institución" placeholder="Seleccione una institución"
                                            itemText="nombre" itemValue="id" hide-details @search="buscarInstitucion"
                                            @load-more="cargarMasInstituciones" clearable />
                                    </v-col>
                                    <v-col cols="12" lg="4" v-if="filtros.institucion">
                                            <ProgresiveLoadAutocomplete v-model="filtros.usuario"
                                            :pageable="usuarios" :loading="usuarios.isLoading"
                                            class="flex-grow-1 flex-shrink-1" style="min-width: 200px;"
                                            label="Usuario" placeholder="Seleccione un usuario"
                                            itemText="nombre" itemValue="id" hide-details @search="buscarUsuario"
                                            @load-more="cargarMasUsuarios" clearable />
                                    </v-col>
                                    <v-col cols="12" lg="4">
                                        <ProgresiveLoadAutocomplete v-model="filtros.pais" :pageable="paises"
                                            :loading="paises.isLoading" class="flex-grow-1 flex-shrink-1"
                                            style="min-width: 200px;" label="País" placeholder="Seleccione un país"
                                            itemText="nombre" itemValue="id" hide-details @search="buscarPais"
                                            @load-more="cargarMasPaises" clearable />
                                    </v-col>
                                    <v-col cols="12" lg="4" v-if="filtros.pais">
                                        <v-text-field hide-details label="Buscar" placeholder="Buscar por estado"
                                            outlined prepend-inner-icon="mdi mdi-magnify" v-model="filtros.estado"
                                            @input="estado" />
                                    </v-col>
                                    <v-menu ref="RangoFechas" v-model="RangoFechas" :close-on-content-click="false"
                                        transition="scale-transition" offset-y min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field outlined v-model="dateRangeText" label="Desde - Hasta"
                                                prepend-inner-icon="mdi-calendar" readonly hide-details v-bind="attrs"
                                                v-on="on" />
                                        </template>
                                        <vc-date-picker title="es" is-range v-model="fechas" mode="date" locale="es"
                                            @input="setFiltrosFechas">
                                        </vc-date-picker>
                                    </v-menu>
                                    <v-col cols="12" lg="4">
                                        <div class="d-flex flex-wrap align-center justify-center" style="gap: 8px;">
                                            <v-btn class="flex-grow-1 flex-shrink-1" x-large outlined color="primary"
                                                @click.stop="limpiarFiltros">
                                                Limpiar
                                            </v-btn>
                                            <v-btn class="flex-grow-1 flex-shrink-1" x-large color="primary"
                                                @click.stop="buscar">
                                                Buscar
                                            </v-btn>
                                        </div>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>

                <DataTableComponent :headers="headers" :items="registros.data" :total_registros="total"
                    v-models:pagina="paginaActual" v-models:select="registrosPorPagina"
                    @paginar="manejarPaginacionRegistros" dense no-gutters class="mt-4" :getRowClass="getRowClass">

                    <template v-slot:item.monto_contratado="{ item }">
                        <span>{{ '$' + item.monto_contratado }}</span>
                    </template>

                    <template v-slot:item.institucion="{ item }">
                        <span>{{ item.Institucion.nombre }}</span>
                    </template>

                    <template v-slot:item.pais="{ item }">
                        <span>{{ item.pais.nombre }}</span>
                    </template>

                    <template v-slot:item.acciones="{ item }">
                        <v-icon @click.stop="openModal(item.id)">mdi-eye</v-icon>
                        <v-icon v-if="item.deleted_at === null && haveRoles(['ROLE_COMPRA_EXTERIOR_ELIMINAR'])"
                            @click.stop="confirmFileDelete(item.id)">mdi-trash-can</v-icon>
                    </template>
                </DataTableComponent>
            </div>
        </v-card>

        <!-- Modal -->
        <v-dialog persistent max-width="1000" v-model="showModal">
            <v-card>
                <v-toolbar color="primary" dark>
                    Detalle de Compra en el Exterior
                </v-toolbar>
                <v-card-text>
                    <v-row class="mt-1">
                        <v-col cols="12" md="6" class="d-flex flex-column">
                            <span> Nombre de la Compra </span>
                            <span class="text-body-1 secondary--text">
                                {{ this.detalleCompra.nombre_compra }}
                            </span>
                        </v-col>
                        <v-col cols="12" md="6" class="d-flex flex-column">
                            <span> Insumos adquiridos </span>
                            <span class="text-body-1 secondary--text">
                                {{ this.detalleCompra.insumos_adquiridos }}
                            </span>
                        </v-col>
                        <v-col cols="12" md="6" class="d-flex flex-column">
                            <span> Tipo de divisa </span>
                            <span class="text-body-1 secondary--text">
                                {{ this.detalleCompra.Divisa?.divisa }}
                            </span>
                        </v-col>
                        <v-col cols="12" md="6" class="d-flex flex-column">
                            <span> Monto contratado </span>
                            <span class="text-body-1 secondary--text">
                                {{ this.detalleCompra.Divisa?.codigo }} {{ this.detalleCompra.monto_contratado }}
                            </span>
                        </v-col>
                        <v-col cols="12" md="6" class="d-flex flex-column">
                            <span> País </span>
                            <span class="text-body-1 secondary--text">
                                {{ this.detalleCompra.pais?.nombre }}
                            </span>
                        </v-col>
                        <v-col cols="12" md="6" class="d-flex flex-column">
                            <span> Estado </span>
                            <span class="text-body-1 secondary--text">
                                {{ this.detalleCompra.estado }}
                            </span>
                        </v-col>
                        <v-col cols="12" md="6" class="d-flex flex-column">
                            <span>Fecha de contratación</span>
                            <span class="text-body-1 secondary--text">
                                {{ moment(this.detalleCompra.fecha_contratacion,
                                    "YYYY-MM-DD"
                                ).format("dddd LL") }}
                            </span>
                        </v-col>
                        <v-col cols="12" md="6" class="d-flex flex-column">
                            <span>Adjuntos de la Compra Exterior</span>
                            <div v-if="detalleCompra.CompraExteriorRegistroInformeAdjuntos?.length > 0">
                                <div v-for="adjunto in detalleCompra.CompraExteriorRegistroInformeAdjuntos"
                                    :key="adjunto.id" class="text-body-1">
                                    <a @click="showAttachment(adjunto.id)" class="secondary--text"
                                        style="cursor: pointer">
                                        Visualizar archivo
                                        <v-icon>mdi-paperclip</v-icon>
                                    </a>
                                </div>
                            </div>
                            <span v-else class="text-body-1 secondary--text">
                                Sin documento
                            </span>
                        </v-col>
                        <v-col cols="12" md="6" class="d-flex flex-column">
                            <span>Fecha de ingreso al sistema</span>
                            <span class="text-body-1 secondary--text">
                                {{ moment(this.detalleCompra.created_at,
                                    "YYYY-MM-DD"
                                ).format("dddd LL") }}
                            </span>
                        </v-col>
                        <v-col cols="12" md="6" class="d-flex flex-column">
                            <span>Ingresado al sistema por</span>
                            <span class="text-body-1 secondary--text">
                                {{ this.detalleCompra.Usuario?.nombre }}
                            </span>
                        </v-col>

                        <!-- Solo aparecera si la comprq fue eliminada -->
                        <v-col v-if="detalleCompra.deleted_at !== null" cols="12" md="6" class="d-flex flex-column">
                            <span>Fecha de eliminación</span>
                            <span class="text-body-1 secondary--text">
                                {{ moment(this.detalleCompra.deleted_at,
                                    "YYYY-MM-DD"
                                ).format("dddd LL") }}
                            </span>
                        </v-col>
                        <v-col v-if="detalleCompra.documento_eliminacion !== null" cols="12" md="6"
                            class="d-flex flex-column">
                            <span>Adjunto de eliminación de la Compra Exterior</span>
                            <a @click="showAttachmentIsDelete(detalleCompra.documento_eliminacion)"
                                class="text-body-1 secondary--text" style="cursor: pointer">
                                Visualizar archivo
                                <v-icon>mdi-paperclip</v-icon>
                            </a>
                        </v-col>

                        <v-col cols="12" class="d-flex flex-column">
                            <span>Proveedor</span>
                            <span class="text-body-1 secondary--text">
                                {{ this.detalleCompra.proveedor }}
                            </span>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="justify-end">
                    <v-btn class="text-capitalize" text @click="showModal = false">
                        Cerrar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <PdfModal :is-open.sync="isPdfModalOpen" :source-loadable="pdfLoadable" filename="documento" />

        <!-- Modal Para Eliminar el Archivo -->
        <v-dialog persistent max-width="1000" v-model="isDeleteConfirmOpen">
            <v-card class="mt-4">
                <v-card-title style="gap: 8px;" class="align-center flex-wrap">
                    <v-icon>mdi-delete-alert</v-icon>
                    <span class="text-uppercase text-subtitle-1 font-weight-bold">
                        Confirmar eliminación del proceso
                    </span>
                </v-card-title>
                <v-divider />
                <v-card-text>
                    <v-col>
                        <v-alert border="right" colored-border type="warning" elevation="2" icon="mdi-alert">
                            Para proceder con la eliminación de la compra, es necesario adjuntar un documento de
                            eliminación.
                        </v-alert>
                        <v-file-input v-model="file" accept="application/pdf" clearable
                            label="Documento de eliminacíon *" outlined prepend-icon=""
                            prepend-inner-icon="mdi-paperclip" />
                    </v-col>
                </v-card-text>

                <v-card-actions class="justify-end">
                    <v-btn text @click="isDeleteConfirmOpen = false">
                        Cancelar
                    </v-btn>
                    <v-btn color="red" dark :disabled="!file" @click.stop="confirmDelete">
                        Confirmar y eliminar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<style>
.invalidRow {
    background-color: #e9b937 !important;
    color: white !important;
}
</style>